import { ConfigProvider } from 'antd';
import { memo } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import RouteController from 'route/RouteController';
import store, { persistor } from 'store/Store';
import FontSizeProvider from 'theme/FontSizeProvider';
import ErrorContainer from 'views/Error/ErrorContainer';
import LoadingScreen from 'views/LoadingScreen/LoadingScreen';

const App = memo(() => {
    return (
        <Provider store={store}>
            <PersistGate loading={<LoadingScreen />} persistor={persistor}>
                <ErrorContainer>
                    <FontSizeProvider>
                        <ConfigProvider
                            componentSize="middle"
                            theme={{
                                token: {
                                    colorInfo: '#34ab71',
                                    fontSize: 12,
                                    borderRadius: 0,
                                    wireframe: false,
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <RouteController />
                        </ConfigProvider>
                    </FontSizeProvider>
                </ErrorContainer>
            </PersistGate>
        </Provider>
    );
});
App.displayName = 'App';

export default App;
