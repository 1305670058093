const routeConstants = {
    MAIN: '/',
    LOGIN: '/login',
    DATABASE_MATRIX: '/database/matrix',
    DATABASE_CREATE: '/database/create',
    DATABASE_DEPLOY_SUCCESS: '/database/:uid/deploy/success',
    DATBASE_DEPLOY: '/database/:uid/deploy',
    RESET_PASSWORD: '/reset-password/:token',
};

export default routeConstants;
