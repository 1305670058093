import { memo } from 'react';
import './loading.scss';

const Spinner = memo(() => {
    return (
        <div className="wrap">
            <div className="loading">
                <div className="bounceball"></div>
                <div className="text">Loading...</div>
            </div>
        </div>
    );
});
Spinner.displayName = 'LoadableLoading';

export default Spinner;
