import { useSelector } from 'react-redux';
import { storeFunc } from 'store/Store';
import { StoreState } from 'store/type';
import { tokenActions } from '.';

export const setToken = (token?: string) => {
    storeFunc.dispatch(tokenActions.SET_ACCESS_TOKEN, token);
};

export const getToken = () => {
    return storeFunc.getState()?.token?.accessToken;
};

export const useToken = () => {
    return useSelector((state: StoreState) => state?.token?.accessToken);
};

export const removeToken = () => {
    storeFunc.dispatch(tokenActions.REMOVE_ACCESS_TOKEN, undefined);
};
