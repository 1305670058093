import { Optional } from 'utils/commonType';

export class ProfileState {
    email: string;
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    phoneNumber: string | null;
    hasCreatedDatabase: boolean = false;

    constructor(data?: IProfileState) {
        this.email = data?.email ?? '';
        this.firstName = data?.firstName ?? null;
        this.lastName = data?.lastName ?? null;
        this.fullName = data?.fullName ?? null;
        this.phoneNumber = data?.phoneNumber ?? null;
        this.hasCreatedDatabase = data?.hasCreatedDatabase ?? false;
    }
}

export type IProfileState = Optional<ProfileState>;
